import _ from "lodash";

import { fetcher } from "../auth";
import { apiUrl } from "./utils";

export interface SpawnerGroup {
  spawnerGroupId: string;
  group: string;
  profileOptions: {
    name: string;
    description: string;
    image: string;
    daskImage: string;
    tags: string[];
    settings: { key: string; value: unknown }[];
    imageTagRegex: string;
    versions: { label: string; tag: string }[];
  }[];
  resourceOptions: {
    resourceOptionId: string;
    presetLabel: string;
  }[];
}

export interface SpawnerGroupsResponseItem {
  spawner_group_id: string;
  group: string;
  profile_options: {
    name: string;
    description: string;
    image: string;
    dask_image: string;
    tags: string[];
    settings: { key: string; value: unknown }[];
    image_tag_regex: string;
    versions: { label: string; tag: string }[];
  }[];
  resource_options: {
    resource_option_id: string;
    preset_label: string;
  }[];
}

interface RequestArguments {
  signal: AbortSignal;
}

export const SpawnerGroups = {
  parseFromApi(data: SpawnerGroupsResponseItem[]): SpawnerGroup[] {
    return _.map(data, (responseItem) => ({
      spawnerGroupId: responseItem.spawner_group_id,
      group: responseItem.group,
      profileOptions: _.map(responseItem.profile_options, (item) => ({
        name: item.name,
        description: item.description,
        image: item.image,
        daskImage: item.dask_image,
        tags: item.tags,
        settings: item.settings,
        imageTagRegex: item.image_tag_regex,
        versions: item.versions,
      })),
      resourceOptions: _.map(responseItem.resource_options, (item) => ({
        resourceOptionId: item.resource_option_id,
        presetLabel: item.preset_label,
      })),
    }));
  },
  prepareForApi(spawnerGroup: SpawnerGroup): SpawnerGroupsResponseItem {
    return {
      spawner_group_id: spawnerGroup.spawnerGroupId,
      group: spawnerGroup.group,
      profile_options: _.map(spawnerGroup.profileOptions, (item) => ({
        name: item.name,
        description: item.description,
        image: item.image,
        dask_image: item.daskImage,
        tags: item.tags,
        settings: item.settings,
        image_tag_regex: item.imageTagRegex,
        versions: item.versions,
      })),
      resource_options: _.map(spawnerGroup.resourceOptions, (item) => ({
        resource_option_id: item.resourceOptionId,
        preset_label: item.presetLabel,
      })),
    };
  },
  async fetch({
    signal,
  }: RequestArguments): Promise<SpawnerGroupsResponseItem[]> {
    const { data } = await fetcher({
      url: apiUrl("spawner-groups/all"),
      signal,
    });
    return data;
  },
  async fetchAndParse({ signal }: RequestArguments): Promise<SpawnerGroup[]> {
    const data = await SpawnerGroups.fetch({ signal });
    return SpawnerGroups.parseFromApi(data);
  },
  create(values: SpawnerGroup) {
    return fetcher({
      url: apiUrl(
        `spawner-groups/${encodeURIComponent(values.spawnerGroupId)}`
      ),
      method: "POST",
      data: _.omit(SpawnerGroups.prepareForApi(values), "spawner_group_id"),
    });
  },
  delete(values: SpawnerGroup) {
    return fetcher({
      url: apiUrl(
        `spawner-groups/${encodeURIComponent(values.spawnerGroupId)}`
      ),
      method: "DELETE",
    });
  },
  update(values: SpawnerGroup) {
    return fetcher({
      url: apiUrl(
        `spawner-groups/${encodeURIComponent(values.spawnerGroupId)}`
      ),
      method: "PATCH",
      data: _.omit(SpawnerGroups.prepareForApi(values), "spawner_group_id"),
    });
  },
};
