import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import _ from "lodash";
import { useMemo } from "react";
import { Dropdown } from "react-bootstrap";

import { SpawnerGroup } from "../../../api";
import { ConfirmerModal } from "../confirmer-modal";
import { CustomDropdownToggleButton } from "../custom-dropdown-toggle-button";
import { Table } from "../table";

const columnHelper = createColumnHelper<SpawnerGroup>();

interface SpawnerGroupsListProps {
  spawnerGroups: SpawnerGroup[];
  onEdit: (spawnerGroup: SpawnerGroup) => void;
  onDelete: (spawnerGroup: SpawnerGroup) => void;
  isSubmitting?: boolean;
}

export const SpawnerGroupsList = ({
  spawnerGroups,
  onEdit,
  onDelete,
  isSubmitting = false,
}: SpawnerGroupsListProps) => {
  const columns = useMemo(
    () => [
      columnHelper.accessor("spawnerGroupId", {
        header: () => "ID",
      }),
      columnHelper.accessor("group", {
        header: () => "Group",
      }),
      columnHelper.accessor((row) => row.profileOptions.length, {
        id: "profiles",
        header: () => "Profiles",
        filterFn: (row, columnId, filterValue) =>
          row.getValue(columnId) === _.toNumber(filterValue),
      }),
      columnHelper.accessor((row) => row.resourceOptions.length, {
        id: "resourceOptions",
        header: () => "Resource options",
        filterFn: (row, columnId, filterValue) =>
          row.getValue(columnId) === _.toNumber(filterValue),
      }),
      columnHelper.display({
        id: "actions",
        header: () => <div className="text-end w-100">Actions</div>,
        cell: ({ row: { original: spawnerGroup } }) => (
          <div className="text-end">
            <Dropdown align="end">
              <Dropdown.Toggle
                as={CustomDropdownToggleButton}
                className="btn btn-outline-dark px-3"
                title="Perform actions on spawner group"
                disabled={isSubmitting}
              >
                <FontAwesomeIcon icon={faEllipsisV} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as="button" onClick={() => onEdit(spawnerGroup)}>
                  Edit
                </Dropdown.Item>
                <ConfirmerModal
                  title="Remove spawner group"
                  body="Are you sure you want to remove spawner group?"
                  isSubmitting={isSubmitting}
                  onConfirm={() => onDelete(spawnerGroup)}
                >
                  {({ open }) => (
                    <Dropdown.Item onClick={open}>Delete</Dropdown.Item>
                  )}
                </ConfirmerModal>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
      }),
    ],
    [isSubmitting, onDelete, onEdit]
  );
  const table = useReactTable({
    initialState: { sorting: [{ id: "spawnerGroupId", desc: false }] },
    data: spawnerGroups,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  return <Table table={table} />;
};
