import _ from "lodash";
import * as Yup from "yup";

export const spawnerGroupSchema = Yup.object({
  spawnerGroupId: Yup.string().required("Spawner group ID is required"),
  group: Yup.string().defined(),
  profileOptions: Yup.array(
    Yup.object({
      name: Yup.string().required("Name is required"),
      description: Yup.string().required("Description is required"),
      tags: Yup.array(Yup.string().required()).required(),
      imageTagRegex: Yup.string(),
      image: Yup.string().required("Image is required"),
      daskImage: Yup.string().required("Image is required"),
      settings: Yup.array(
        Yup.object({
          key: Yup.string().required("Key is required"),
          value: Yup.mixed()
            .test("is-json", "Value must be a valid JSON type", (value) => {
              const attempt = _.attempt(() => JSON.stringify(value));
              return !_.isError(attempt);
            })
            .required("Value is required"),
        }).required()
      ).required(),
      versions: Yup.array(
        Yup.object({
          tag: Yup.string().required("Tag is required"),
          label: Yup.string().required("Label is required"),
        }).required()
      ).required(),
    }).required()
  )
    .test({
      name: "unique-name",
      message: "Names must be unique. Duplicate found: ${name}",
      test: function (value, ctx) {
        if (_.isUndefined(value)) {
          return true;
        }
        const errors: Yup.ValidationError[] = [];
        const names = new Set();
        _.each(value, ({ name }, index) => {
          if (names.has(name)) {
            errors.push(
              ctx.createError({
                path: `${ctx.path}[${index}].name`,
                message: `Name '${name}' is already used.`,
                params: { name },
              })
            );
          }
          names.add(name);
        });
        return _.isEmpty(errors) || _.first(errors);
      },
    })
    .required("Profile options is required"),
  resourceOptions: Yup.array(
    Yup.object({
      resourceOptionId: Yup.string().required("Resource option ID is required"),
      presetLabel: Yup.string(),
    })
  ).required("Resource options is required"),
});
